import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import styled, { css, keyframes } from 'styled-components'
import { graphql, StaticQuery } from 'gatsby'
import axios from 'axios'

import {
  Button,
  Page,
  Container,
  Typography,
  SEO,
  SEOProps,
} from '~/components'
import IndexLayout from '~/layouts'

interface ContactProps {
  email: string
  name: string
  message?: string
}

interface InputProps {
  error: boolean
}

interface StaticQueryProps {
  site: {
    siteMetadata: {
      contactFormUrl: string
    }
  }
}

const ShakeAnimation = keyframes`
  0% { transform: translateX(2px); }
  10% { transform: translateX(-1px); }
  20% { transform: translateX(-3px); }
  30% { transform: translateX(0px); }
  40% { transform: translateX(1pxx); }
  50% { transform: translateX(-1px);; }
  60% { transform: translateX(-3px); }
  70% { transform: translateX(2px); }
  80% { transform: translateX(-1px); }
  90% { transform: translateX(2px); }
  100% { transform: translateX(1px); }
`

const ShowError = css<InputProps>`
  border-color: ${(p) => (p.error ? p.theme.colors.error : 'transparent')};
  animation-name: ${ShakeAnimation};
  animation-duration: 0.5s;
  animation-iteration-count: 1;
`

const Form = styled.form`
  width: 100%;
`

const InputBase = css`
  width: 100%;
  min-width: 0;
  background-color: ${(p) => p.theme.colors.faded};
  color: white;
  outline: 0;
  position: relative;
  appearance: none;
  transition: all 0.2s;
  padding: ${(p) => p.theme.spacings[2]}rem;
  font-family: ${(p) => p.theme.fonts.primary};
  font-size: ${(p) => p.theme.dimensions.fontSize.regular}rem;
  border-radius: ${(p) => p.theme.borders.large}px;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
`

const Input = styled.input<InputProps>`
  ${InputBase}
  height: 4rem;
  width: 100%;
  @media (min-width: ${(p) => p.theme.breakpoints.md}em) {
    width: 50%;
  }
  ${(p) => p.error && ShowError}
`

const TextArea = styled.textarea`
  ${InputBase}
  height: 16rem;
  width: 100%;
  @media (min-width: ${(p) => p.theme.breakpoints.md}em) {
    width: 75%;
  }
`

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-family: ${(p) => p.theme.fonts.monospace};
  font-size: ${(p) => p.theme.dimensions.headingSizes.h5}rem;
  font-weight: ${(p) => p.theme.dimensions.fontWeight.bold};
  line-height: ${(p) => p.theme.dimensions.lineHeight.regular};
  text-transform: uppercase;
`

const Field = styled.div`
  margin-bottom: ${(p) => p.theme.spacings[3]}rem;
`

const Toast = styled.div`
  max-width: 50%;
  transform: translateX(-50%);
  padding: ${(p) => p.theme.spacings[2]}rem;
  background-color: ${(p) => p.theme.colors.success};
  border-radius: ${(p) => p.theme.borders.large}px;
  position: fixed;
  top: 1rem;
  left: 50%;
  z-index: ${(p) => p.theme.zindex.modals};
`

const Contact = () => (
  <StaticQuery
    query={query}
    render={(data: StaticQueryProps) => {
      const { contactFormUrl } = data.site.siteMetadata
      const seo: SEOProps = {
        title: 'Contact',
        path: '/contact',
        article: false,
      }
      const {
        register,
        handleSubmit,
        errors,
        reset,
        formState,
      } = useForm<ContactProps>()
      const { isDirty } = formState
      const [toast, setToast] = useState(false)

      const onSubmit = async (data: ContactProps) => {
        // Post to Form Endpoint
        try {
          await axios.post(contactFormUrl, data, {
            headers: {
              'content-type': 'application/json',
            },
          })
        } catch (error) {
          console.error(error.response)
        }

        // Set Toast Message
        setToast(true)

        // Reset Form
        reset()

        // Quick hack to hide the toast after a few seconds
        setTimeout(() => {
          setToast(false)
        }, 6000)
      }

      return (
        <IndexLayout>
          <SEO
            title={seo.title}
            description={seo.description}
            image={seo.image}
            path={seo.path}
            article={seo.article}
          />
          {toast && !isDirty && (
            <Toast>Thank you! I'll be in touch shortly.</Toast>
          )}
          <Page>
            <Container>
              <Typography variant="h1">Contact Me</Typography>
              <Typography>Feel free to get in touch about anything:</Typography>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Field>
                  <Label htmlFor="name">Name</Label>
                  <Input
                    type="text"
                    name="name"
                    placeholder="Your Name"
                    ref={register({ required: true })}
                    error={errors.name ? true : false}
                  />
                </Field>
                <Field>
                  <Label htmlFor="email">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    ref={register({ required: true })}
                    error={errors.email ? true : false}
                  />
                </Field>
                <Field>
                  <Label htmlFor="message">Message</Label>
                  <TextArea
                    name="message"
                    placeholder="Enter a message if you'd like..."
                    ref={register}
                  />
                </Field>
                <Button type="submit">Send</Button>
              </Form>
            </Container>
          </Page>
        </IndexLayout>
      )
    }}
  />
)

export default Contact

const query = graphql`
  query ContactForm {
    site {
      siteMetadata {
        contactFormUrl
      }
    }
  }
`
